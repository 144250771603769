import { stringToPascalCase } from '@utils/helpers';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

export type Breadcrumb = {
	crumbLabel: string;
	pathname: string;
};

const convertToTitle = (string: string): string => {
	const words = string.split('-');

	for (var i = 0; i < words.length; i++) {
		const word = words[i];
		words[i] = word.charAt(0).toUpperCase() + word.slice(1);
	}

	return words.join(' ');
};

const Breadcrumbs = ({ crumbs }: { crumbs: Breadcrumb[] }) => {
	const { t } = useTranslation();

	const cleanedCrumbs = crumbs.map(crumb => {
		return {
			crumbLabel: t(`_${stringToPascalCase(crumb.crumbLabel)}`), /* i18next-extract-disable-line */
			pathname: crumb.pathname.replace('/ja', '')
		}
	}).filter(crumb => crumb.pathname);

	return (
		<div className="breadcrumbs">
			<div className="row">
				<div className="col xl12">
					{cleanedCrumbs.map(({ pathname, crumbLabel }, key) => {
						const label = crumbLabel.replace(/-/g, ' ');

						return key + 1 !== cleanedCrumbs?.length ? (
							<React.Fragment key={key}>
								<Link to={pathname}>
									{convertToTitle(label)}
								</Link>
								{key !== cleanedCrumbs.length - 1 && (
									<span className="breadcrumb-separator">
										/
									</span>
								)}
							</React.Fragment>
						) : null;
					})}
				</div>
			</div>
		</div>
	);
};

export default Breadcrumbs;
