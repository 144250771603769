import { ImageObject } from '@utils/types';
import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const Banner = ({
	title,
	image,
	as: Component = 'section',
	className = '',
	size='narrow',
}: {
	title: string;
	image: ImageObject;
	as?: React.ElementType;
	className?: string;
	size?: 'narrow' | 'standard';
}) => {
	const gatsbyImage = getImage(image.src);

	return (
		<Component
			className={classNames('hero', {
				[`hero--${size}`]: size,
				[className]: !!className,
			})}>
			<div className="hero__aligner">
				<div className="hero__row">
					<div className="row">
						<header className="col xl12 hero__header">
							<div className="hero__content">
								<h1 className="hero__title">{title}</h1>
							</div>
						</header>
					</div>
				</div>
			</div>
			{gatsbyImage && (
				<div className="hero__bkg image-background">
					<GatsbyImage
						image={gatsbyImage}
						alt={image.alt}
						objectFit="cover"
					/>
				</div>
			)}
		</Component>
	);
};

export default Banner;
