import classNames from 'classnames';
import React from 'react';

const Deck = ({
	children,
	as: Component = 'div',
	className = '',
	columns = 3,
}: {
	children: React.ReactNode[];
	as?: React.ElementType;
	className?: string;
	columns?: 2 | 3 | 4;
}) => {
	return (
		<Component
			className={classNames('deck', {
				[className]: !!className,
			})}>
			{children.map((child, key) => (
				<div
					key={key}
					className={classNames('col', {
						'xl3 l4 m6 s12': columns === 4,
						'xl4 m6 s12': columns === 3,
						'xl6 s12': columns === 2,
					})}>
					{child}
				</div>
			))}
		</Component>
	);
};

export default Deck;
