import { LINKS } from '@utils/constants';
import {
	BackgroundColours,
	IResourceFrontmatter,
	LayoutTypes,
} from '@utils/types';
import React from 'react';

import Deck from '@components/Deck';
import PageSection from '@components/PageSection';
import ResourceCard from '@components/ResourceCard';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { stringToPascalCase } from '@utils/helpers';

const ResourceListing = ({
	resources,
	title,
	theme = 'white',
	layout = 'wide',
	actions,
}: {
	resources: { nodes: IResourceFrontmatter[] };
	title?: string;
	theme?: BackgroundColours;
	layout?: LayoutTypes;
	actions?: React.ReactNode;
}) => {
	const { t } = useTranslation();

	return (
		<PageSection
			className="section__resources__listing"
			layout={layout}
			theme={theme}>
			{title && (
				<div className="section__header">
					<h1 className="section__title">{title}</h1>
				</div>
			)}
			<Deck>
				{resources.nodes.map(
					(node: IResourceFrontmatter, i: number) => (
						<ResourceCard
							key={i}
							title={node.frontmatter.title}
							type={t(`_Type${stringToPascalCase(node.frontmatter.type)}`) /* i18next-extract-disable-line */}
							image={node.frontmatter.image}
							link={`${LINKS.INSIGHTS}${node.fields.slug}`}
						/>
					)
				)}
			</Deck>
			{actions}
		</PageSection>
	);
};

export default ResourceListing;
