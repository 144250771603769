import { IResource } from '@utils/types';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const ResourceCard = ({ title, type, image, link }: IResource) => {
	const gatsbyImage = image && getImage(image?.childImageSharp.gatsbyImageData);

	return (
		<article className="card resource-card--new">
			{gatsbyImage && (
				<div className="resource-card__image">
					<div className="image-background">
						<GatsbyImage
							image={gatsbyImage}
							alt={title}
							objectFit="cover"
						/>
					</div>
				</div>
			)}
			<div className="resource-card__content">
				<h1 className="resource-card__title">{title}</h1>
				<span className="resource-card__type">{type}</span>
			</div>
			<Link to={link} className="resource-card__link link-coverer">
				Download resource
			</Link>
		</article>
	);
};

export default ResourceCard;
