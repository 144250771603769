import { BackgroundColours, LayoutTypes } from '@utils/types';
import classNames from 'classnames';
import React from 'react';

const PageSection = ({
	title,
	children,
	as: Component = 'section',
	className = '',
	theme = 'white',
	layout = 'inline',
}: {
	title?: string;
	children: React.ReactNode;
	as?: React.ElementType;
	className?: string;
	theme?: BackgroundColours;
	layout?: LayoutTypes;
}) => {
	return (
		<Component
			className={classNames('section section--padding', {
				[className]: !!className,
				[`background--${theme}`]: theme,
			})}>
			<div
				className={classNames('row', {
					'row--large': layout === 'fullwidth',
				})}>
				{title && (
					<div className="section__header col xl8 offset-xl2">
						<h1>{title}</h1>
					</div>
				)}
				<div
					className={classNames({
						'col xl8 offset-xl2 l10 offset-l1 m12 offset-m0 page__content':
							layout === 'inline',
						'col xl12': layout === 'fullwidth' || layout === 'wide',
					})}>
					{children}
				</div>
			</div>
		</Component>
	);
};

export default PageSection;
